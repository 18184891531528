
.App-header {
  display: block;
  align-items: start;
  padding: 1.5vmin 3vmin;
}

.Nav-items {
  list-style: none;
  display: block;
  align-items: start;
  padding: 2vw;
  padding-inline-start: 0vh;
}

.Nav-logo {
  display: inline-block;
}

.App-logo {
  display: inline-block;
  height: 3vmax;
}

.Nav-item {
  display: inline-block;
}

.App-body{
  background-color: darkorange;
  min-height: 20vh;
  height: 80vh;
  display: flex;
  flex-direction: column;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
  text-align: center;
}

.App-footer {
  display: flex;
  flex-direction: column;
  justify-content: center;
  font-size: calc(5px + 1.5vmin);
  color: #4c4c4c;
  text-align: center;
}

.Txt_bar {
  margin: 0 1vw;
}

.Legal_link {
  color: #4c4c4c;
  text-decoration: none;
}


